import React from 'react'

import Layout from '../components/layout'

const TermsPage = () => {
  return (
    <Layout className="terms">
      <section className="content">
        <h1>
          Undefined Labs, Inc. – Standard Terms and Conditions (Hosted Service)
        </h1>

        <p>
          The following Standard Terms and Conditions govern Customer’s access
          to and use of the Hosted Service.
        </p>

        <h2>1. Definitions</h2>
        <ol>
          <li>
            <strong>“Customer Data”</strong> means any and all data and
            information uploaded or imported into the Hosted Service by or on
            behalf of Customer, including without limitation metadata and
            information obtained from Repositories (e.g., branches, names of
            branches, commits, authors, descriptions of commits, timing
            information).
          </li>
          <li>
            <strong>“End Users”</strong> means Customer’s individual employees
            who use the Hosted Service.
          </li>
          <li>
            <strong>“Fees”</strong> means, individually and collectively, the
            fees and expenses set forth in the Order.
          </li>
          <li>
            <strong>“Intellectual Property Rights”</strong> means all forms of
            proprietary rights, titles, interests, and ownership relating to
            patents, copyrights, trademarks, trade dresses, trade secrets,
            know-how, mask works, droit moral (moral rights), and all similar
            rights of every type that may exist now or in the future in any
            jurisdiction, including without limitation all applications and
            registrations therefore and rights to apply for any of the
            foregoing.
          </li>
          <li>
            <strong>“Order”</strong> means the Order Form document which is
            executed by authorized representatives of each party and to which
            this Exhibit A is attached.
          </li>
          <li>
            <strong>“Hosted Service”</strong> means Undefined Labs’
            software-as-a-service offering identified in the Order.
          </li>
          <li>
            <strong>“Repositories”</strong> means source code repositories which
            Customer links to the Hosted Service.
          </li>
        </ol>

        <h2>2. Hosted service</h2>
        <ol>
          <li>
            <strong>Provision of Hosted Service</strong>. Subject to all terms
            and conditions of this Agreement, Customer shall have the right to
            access and use the Hosted Service through up to the maximum number
            of simultaneous End Users specified in the Order during the Term,
            solely in furtherance of Customer’s own software development efforts
            and solely in the manner enabled by Undefined Labs and in accordance
            with all applicable documentation. Undefined Labs reserves the right
            to modify and update the features and functionality of the Hosted
            Service from time to time. Except for as expressly set forth herein,
            Customer is solely responsible for purchasing and configuring all
            hardware, software and services that may be necessary or desirable
            for Customer’s use of the Hosted Service. Customer agrees to use the
            Hosted Service in compliance with all applicable laws, rules and
            regulations, and Customer agrees that Customer is solely responsible
            for its compliance with all such applicable laws, rules, and
            regulations.
          </li>
          <li>
            <strong>Restrictions</strong>. Customer acknowledges that use of the
            Hosted Service is provided solely for Customer’s internal use only
            in connection with Repositories owned by Customer, and agrees not to
            use the Hosted Service for the benefit of any third party. Customer
            agrees not to, not to attempt to, nor allow any third party to: (i)
            copy, distribute, rent, lease, lend, sublicense or transfer the
            Hosted Service, make the Hosted Service available to any third party
            or use the Hosted Service on a service bureau or time sharing basis,
            (ii) decompile, reverse engineer, or disassemble the Hosted Service
            or otherwise attempt to reconstruct or discover any source code,
            underlying ideas, algorithms, file formats or programming interfaces
            of the Hosted Service, (iii) create derivative works based on the
            Hosted Service; (iv) modify, remove, or obscure any copyright,
            trademark, patent or other notices or legends that appear on the
            Hosted Service or during the use and operation thereof; (v) publicly
            disseminate performance information or analysis (including
            benchmarks) relating to the Hosted Service; (vi) use the Hosted
            Service in connection with any Repositories not owned by Customer;
            or (vii) use the Hosted Service to develop a competitive product
            offering. Customer may not use any automated means, including
            agents, robots, scripts, or spiders, to access or manage the Hosted
            Service, except solely to the extent as may be specifically enabled
            and authorized by Undefined Labs.
          </li>
          <li>
            <strong>Suspension/Termination</strong>. Undefined Labs may
            terminate Customer’s access to or use of the Hosted Service and/or
            terminate this Agreement at any time if: (i) in the sole discretion
            of Undefined Labs, such action is necessary to prevent material
            errors or harm to any system or network, or to limit Undefined Labs’
            liability; or (ii) Customer attempts to access or use the Hosted
            Service in an unauthorized manner, including without limitation any
            use for a third party other than a Customer, any attempt to gain
            access to data or information relating to other Undefined Labs
            clients or any use that infringes third party Intellectual Property
            Rights or violates any applicable law, rule or regulation.
          </li>
          <li>
            <strong>Accounts</strong>. Customer is responsible for the
            activities of any and all persons accessing and using the Hosted
            Service using any End User’s user name and password. Customer shall,
            and shall instruct its End Users to, use all reasonable means to
            secure user names and passwords, and shall promptly notify Undefined
            Labs if it suspects that any user name and password has been
            compromised.
          </li>
          <li>
            <strong>Third Party Services and Modifications</strong>. The Hosted
            Service may include features or functionality that interoperate with
            online source code repositories and other services operated by third
            parties (such services, <strong>“Third Party Services”</strong>),
            pursuant to agreements between Undefined Labs and the operators of
            such Third Party Services (such agreements,{' '}
            <strong>“Third Party Agreements”</strong> and such operators,{' '}
            <strong>“Operators”</strong>) or through application programming
            interfaces or other means of interoperability made generally
            available by the Operators (<strong>“Third Party APIs”</strong>)
            which Undefined Labs does not control. Third Party Agreements and
            Third Party APIs (and the policies, terms and rules applicable to
            Third Party APIs) may be modified, suspended or terminated at any
            time. Any such modification, suspension or termination shall not
            affect any payment obligations under this Agreement and Undefined
            Labs shall have no liability with respect thereto. Without limiting
            the foregoing, Customer is responsible for ensuring that Customer’s
            use of the Hosted Service in connection with Third Party Services
            complies with all policies, terms and rules applicable thereto.
          </li>
          <li>
            <strong>Support</strong>. Subject to all terms and conditions
            herein, Undefined Labs will use commercially reasonable efforts to
            respond to and repair problems with the Hosted Service that are
            reported to Undefined Labs by Customer during normal business hours,
            which is Monday thru Friday from 9:00AM to 5:00PM PST (excluding
            Undefined Labs’ company holidays). Support will be provided via
            e-mail and such other electronics means as Undefined Labs may
            support from time to time (e.g., Slack). Only the Customer’s
            designated administrative contact(s) as named in the Order may
            request support. Undefined Labs will use commercially reasonable
            efforts to resolve each case. Actual resolution time will depend on
            the nature of the case and the resolution. A resolution may consist
            of a fix, workaround or other solution in Undefined Labs’ reasonable
            determination.
          </li>
          <li>
            <strong>Control</strong>. Customer acknowledges and agrees that
            Undefined Labs has no obligation to monitor or edit the Customer
            Data, and that as between the parties Customer is solely responsible
            for the Customer Data. Undefined Labs reserves the right to remove
            any Customer Data which Undefined Labs becomes aware may violate the
            terms of this Agreement or infringe, misappropriate or violate any
            third party Intellectual Property Right or privacy right.
          </li>
          <li>
            <strong>Data</strong>. As between the parties, Customer shall own
            all right, title and interest in and to Customer Data. Customer
            hereby grants Undefined Labs a non-exclusive, worldwide license to
            view, use, reproduce, modify, create derivative works of, display,
            perform and transmit the Customer Data in connection with Undefined
            Labs’ operation of the Hosted Service. Customer acknowledges and
            agrees that Customer Data imported for use within Hosted Service may
            reside on or be transmitted through Undefined Labs’ systems.
            Undefined Labs will use commercially reasonable security measures to
            prevent unauthorized access to the Customer Data, however, Customer
            acknowledges that: (i) no security measures are 100% effective and
            (ii) the Hosted Service uses the Internet for data transfer, and
            Internet communications have inherent insecurities. As between the
            parties, Undefined Labs shall own all right, title and interest in
            and to all data generated by Undefined Labs in connection with the
            operation of the Hosted Service and Customer’s use thereof (“Usage
            Data”). Usage Data may include, by way of example and not
            limitation, when and how often End Users use the Hosted Service and
            which Hosted Service features are used the most often. Undefined
            Labs will not disclose Usage Data or Customer Data to any third
            party in a manner that identifies Customer or any End User without
            Customer’s consent other than (i) disclosure to Undefined Labs’
            third party service providers who use it for the benefit of
            Undefined Labs and subject to reasonable confidentiality terms; or
            (ii) as may be required by law or legal process.
          </li>
        </ol>

        <h2>3. Payment</h2>
        <ol>
          <li>
            <strong>Fees</strong>. Customer shall pay Undefined Labs the Fees as
            set forth in the Order. All recurring monthly Fees under the Order
            shall be due and payable in advance of the applicable month. All
            other Fees are due and payable in arrears except to the extent
            otherwise set forth in the Order.
          </li>
          <li>
            <strong>Payment Terms</strong>. Unless otherwise stated by the
            parties in the Order, Undefined Labs shall invoice Customer for Fees
            on a monthly basis. Unless otherwise set forth in the Order,
            Customer agrees to pay each invoice within thirty (30) days of the
            invoice date, provided that recurring monthly fees under the Order
            must be received by Undefined Labs prior to the first day of the
            applicable month regardless of the invoice date. All payments will
            be made in U.S. dollars. Any amounts due to Undefined Labs under
            this Agreement not received by the date due will be subject to a
            late fee of 1.5% per month, or the maximum charge permitted by law,
            whichever is less. Customer shall pay the amounts due under each
            invoice without deducting any taxes that may be applicable to such
            payments. Customer is responsible for paying any and all
            withholding, sales, value added or other taxes, duties or charges
            applicable to this Agreement, other than taxes based on Undefined
            Labs’ income.
          </li>
        </ol>

        <h2>4. Ownership</h2>
        <ol>
          <li>
            <strong>Undefined Labs</strong>. As between the parties, Undefined
            Labs owns all right, title and interest (including all Intellectual
            Property Rights) in and to the Hosted Service (including without
            limitation all underlying source code, algorithms, models, features,
            or functionality) and any software, technology, materials and
            information (i) owned by Undefined Labs prior to the Effective Date
            or (ii) created, authored, developed, made, conceived, or reduced to
            practice by Undefined Labs after the Effective Date (collectively,
            the <strong>“Hosted Service Materials”</strong>). Nothing herein
            shall be construed to transfer any rights, title or ownership of the
            Hosted Service, the Hosted Service Materials, or any Undefined Labs
            software, technology, materials, information or Intellectual
            Property Rights to Customer. Customer is not required to provide any
            ideas, feedback or suggestions regarding any of Undefined Labs’
            products or services (<strong>“Feedback”</strong>) to Undefined
            Labs. To the extent Customer does provide any Feedback to Undefined
            Labs, Customer agrees to assign and hereby does assign all right,
            title and interest in and to such Feedback to Undefined Labs and
            acknowledges that Undefined Labs may freely use, reproduce, modify,
            distribute, make, have made, sell, offer for sale, import and
            otherwise exploit in any manner such Feedback without payment of any
            royalties or other consideration to Customer.
          </li>
          <li>
            <strong>Customer</strong>. As between the parties, Customer owns all
            right, title and interest (including all Intellectual Property
            Rights) in and to the Customer Data and any software, technology,
            materials and information owned by Customer prior to the Effective
            Date or created, authored, developed, made, conceived or reduced to
            practice by Customer after the Effective Date. Nothing herein shall
            be construed to transfer any rights, title or ownership of the
            Customer Data or any Customer software, technology, materials,
            information or Intellectual Property Rights to Undefined Labs.
          </li>
        </ol>

        <h2>5. Term; Termination</h2>
        <ol>
          <li>
            <strong>Term</strong>. The Term of this Agreement is as set forth in
            the Order.
          </li>
          <li>
            <strong>Termination</strong>.
            <ol type="a">
              <li>
                Either party may terminate this Agreement effective immediately
                if the other party is in material breach of any obligation,
                representation or warranty hereunder and fails to cure such
                material breach (if capable of cure) within thirty (30) days (or
                ten (10) days in the event of breach of payment obligations)
                after receiving written notice of the breach from the
                non-breaching party.
              </li>
              <li>
                Either party may terminate this Agreement immediately upon
                written notice at any time if: (i) the other party files a
                petition for bankruptcy or is adjudicated as bankrupt; (ii) a
                petition in bankruptcy is filed against the other party and such
                petition is not removed or resolved within sixty (60) calendar
                days; (iii) the other party makes an assignment for the benefit
                of its creditors or an arrangement for its creditors pursuant to
                bankruptcy law; (iv) the other party discontinues its business;
                (v) a receiver is appointed over all or substantially all of the
                other party’s assets or business; or (vi) the other party is
                dissolved or liquidated.
              </li>
            </ol>
          </li>
          <li>
            <strong>Effect of Termination</strong>. All rights and obligations
            of the parties hereunder shall terminate upon expiration or
            termination of this Agreement, provided that Sections 1, 2.2, 2.5,
            2.7, 2.8, 2.9, 3 (with respect to accrued but unpaid Fees), 4, 5.3,
            7, 8, 9, 10 and 11 shall survive expiration or termination of this
            Agreement.
          </li>
        </ol>

        <h2>6. Representations and warranties</h2>
        <p>
          Each party represents and warrants to the other party that: (i) it has
          the full power and authority to enter into this Agreement; (ii) the
          execution of this Agreement and performance of its obligations under
          this Agreement does not violate any other agreement to which it is a
          party; and (iii) this Agreement constitutes a legal, valid and binding
          obligation when executed and delivered.
        </p>

        <h2>7. Indemnification</h2>
        <ol>
          <li>
            <strong>Customer Indemnity</strong>. Customer agrees to, at its own
            expense, defend and/or settle any claim, action or suit brought by a
            third party against Undefined Labs or its directors, officers or
            employees (<strong>“Undefined Labs Indemnitees”</strong>) arising
            out of or relating to Customer’s use of the Hosted Service other
            than an IP Claim (any of the foregoing, a <strong>“Claim”</strong>).
            Customer will pay those amounts finally awarded by a court of
            competent jurisdiction against Undefined Labs Indemnitees, or
            subject to the terms of Section 7.3, payable pursuant to a
            settlement agreement with respect to the Claim.
          </li>
          <li>
            <strong>Undefined Labs Indemnity</strong>. Undefined Labs agrees to,
            at its own expense, defend and/or settle any claim, action or suit
            brought by a third party against Customer or its Affiliates, or
            their directors, officers and employees (
            <strong>“Customer Indemnitees”</strong>), alleging that Undefined
            Labs’ technology underlying the Hosted Service infringes the United
            States copyright or trade secret of such third party (an{' '}
            <strong>“IP Claim”</strong>), Undefined Labs will pay those amounts
            finally awarded by a court of competent jurisdiction against the
            Customer Indemnitees, or subject to the terms of Section 7.3,
            payable pursuant to a settlement agreement with respect to the IP
            Claim. If Undefined Labs, in its sole discretion, believes an IP
            Claim or an adverse judgment in connection with an IP Claim is
            likely, then Undefined Labs may, at its option, (a) obtain a license
            from such third party claimant that allows Customer to continue the
            use of the Hosted Service, (b) modify the Hosted Service so as to be
            non-infringing, or (c) if neither (a) nor (b) is available to
            Undefined Labs on commercially reasonable terms, terminate this
            Agreement upon written notice to Customer and refund to Customer any
            prepaid Service Fees on a pro-rata basis. Undefined Labs will have
            no obligation or liability relating to any IP Claim that: (x) is
            based on modification or customization of the Hosted Service at the
            direction of Customer or any third party; (y) is based on the
            combination or use of the Hosted Service (or any component of
            either) with any software, hardware, system, method, device or
            materials not provided or required by Undefined Labs; or (z) results
            from Customer’s use of the Hosted Service in a manner that is
            inconsistent with its intended use or is in breach of this
            Agreement. This Section 7.2 sets forth the entire liability of
            Undefined Labs and the sole and exclusive remedy of Customer in the
            event of any claim that the Hosted Service infringes any third party
            Intellectual Property Right.
          </li>
          <li>
            <strong>Indemnification Procedure</strong>. Each party shall
            promptly notify the other party in writing of any action for which
            such party believes it is entitled to be indemnified pursuant to
            Section 7.1 or Section 7.2, as the case may be. The party seeking
            indemnification (the <strong>“Indemnitee”</strong>) shall cooperate
            with the other party (the <strong>“Indemnitor”</strong>) at the
            Indemnitor's sole cost and expense. The Indemnitor shall immediately
            take control of the defense and investigation of such Claim or IP
            Claim and shall employ counsel reasonably acceptable to the
            Indemnitee to handle and defend the same, at the Indemnitor's sole
            cost and expense. The Indemnitee's failure to perform any
            obligations under this Section 7.3 will not relieve the Indemnitor
            of its obligations under this Section 7 except to the extent that
            the Indemnitor can demonstrate that it has been materially
            prejudiced as a result of such failure. The Indemnitee may
            participate in and observe the proceedings at its own cost and
            expense with counsel of its own choosing. The Indemnitor may settle
            a Claim or IP Claim so long as any settlement (i) does not, without
            Indemnitee’s prior written approval, (x) involve the admission of
            any wrongdoing by any Indemnitee, (y) restrict any Indemnitee’s
            future actions, or (z) require any Indemnitee to take any action,
            including the payment of money, and (ii) includes a full release of
            the Indemnitees.
          </li>
        </ol>

        <h2>8. Disclaimer</h2>
        <p>
          EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, NEITHER PARTY MAKES
          ANY WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE SUBJECT MATTER
          OF THIS AGREEMENT, AND EACH PARTY EXPRESSLY DISCLAIMS THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, NONINFRINGEMENT, FITNESS FOR A
          PARTICULAR PURPOSE AND IMPLIED WARRANTIES ARISING FROM COURSE OF
          DEALING OR PERFORMANCE. Undefined Labs and ITS SUPPLIERS, PARTNERS AND
          SERVICE PROVIDERS DO NOT WARRANT THAT THE FUNCTIONALITY PROVIDED BY
          THE HOSTED SERVICE WILL BE CORRECT, UNINTERRUPTED OR ERROR-FREE OR
          THAT DEFECTS WILL BE CORRECTED. Undefined Labs DOES NOT WARRANT THE
          RESULTS OF USE OF THE HOSTED SERVICE. WITHOUT LIMITING THE GENERALITY
          OF THE FOREGOING, CUSTOMER ACKNOWLEDGES THAT THE HOSTED SERVICE is not
          designed, manufactured or intended for use in hazardous environments
          requiring fail-safe performance, such as in the operation of nuclear
          facilities, aircraft navigation or air traffic control, weapons
          systems, life-support machines, or any other application in which the
          failure of the HOSTED SERVICE could lead directly to death, personal
          injury, or severe physical or property damage or environmental damage
          (collectively, <strong>“High Risk Activities”</strong>). UNDEFINED
          LABS expressly disclaims any express or implied guarantee of fitness
          for such High Risk Activities.
        </p>

        <h2>9. Confidentiality</h2>
        <ol>
          <li>
            <strong>Definition</strong>.{' '}
            <strong>“Confidential Information”</strong> means any information
            disclosed by either party to the other party, either directly or
            indirectly, in writing, orally or by inspection of tangible objects
            (including without limitation documents, prototypes, samples, plant
            and equipment), which is designated as "Confidential," "Proprietary"
            or some similar designation. Information communicated orally will be
            considered Confidential Information if such information is confirmed
            in writing as being Confidential Information within a reasonable
            time after the initial disclosure. Confidential Information may also
            include information disclosed to a disclosing party by third
            parties. Confidential Information will not, however, include any
            information which (a) was publicly known and made generally
            available in the public domain prior to the time of disclosure by
            the disclosing party; (b) becomes publicly known and made generally
            available after disclosure by the disclosing party to the receiving
            party through no action or inaction of the receiving party; (c) is
            already in the possession of the receiving party at the time of
            disclosure by the disclosing party as shown by the receiving party’s
            files and records immediately prior to the time of disclosure;
            (d) is obtained by the receiving party from a third party without a
            breach of such third party’s obligations of confidentiality; or
            (e) is independently developed by the receiving party without use of
            or reference to the disclosing party’s Confidential Information, as
            shown by documents and other competent evidence in the receiving
            party’s possession. Confidential Information of Undefined Labs will
            include without limitation the Hosted Service and any related
            documentation.
          </li>
          <li>
            <strong>Non-Use and Non-Disclosure</strong>. Each party agrees not
            to use any Confidential Information of the other party for any
            purpose except to exercise its rights and perform its obligations
            under this Agreement. Each party agrees not to disclose any
            Confidential Information of the other party to third parties or to
            such party’s employees, except to those employees of the receiving
            party with a need to know. Neither party shall reverse engineer,
            disassemble or decompile any prototypes, software or other tangible
            objects which embody the other party’s Confidential Information and
            which are provided to the party hereunder.
          </li>
          <li>
            <strong>Maintenance of Confidentiality</strong>. Each party agrees
            that it shall take reasonable measures to protect the secrecy of and
            avoid disclosure and unauthorized use of the Confidential
            Information of the other party. Without limiting the foregoing, each
            party shall take at least those measures that it takes to protect
            its own most highly confidential information and shall ensure that
            its employees and contractors who have access to Confidential
            Information of the other party have signed a non-use and
            non-disclosure agreement in content similar to the provisions
            hereof, prior to any disclosure of Confidential Information to such
            employees. Neither party shall make any copies of the Confidential
            Information of the other party unless the same are previously
            approved in writing by the other party. Each party shall reproduce
            the other party’s proprietary rights notices on any such approved
            copies, in the same manner in which such notices were set forth in
            or on the original.
          </li>
          <li>
            <strong>Required Disclosure</strong>. Neither party shall be in
            breach of this Section 9 for any disclosure of the other party’s
            Confidential Information that such party is required by law or legal
            process to make, provided that the party subject to such requirement
            gives the other party prompt written notice of such requirement
            prior to such disclosure and assistance in obtaining an order
            protecting the information from public disclosure.
          </li>
          <li>
            <strong>Return of Materials</strong>. Upon the termination of this
            Agreement, each party shall deliver to the other party all of such
            other party’s Confidential Information that such party may have in
            its possession or control.
          </li>
        </ol>

        <h2>10. Limitation of Liability</h2>
        <p>
          EXCEPT WITH RESPECT TO SECTION 7 OR EITHER PARTY’S BREACH OF SECTION
          9, NEITHER PARTY SHALL BE LIABLE TO THE OTHER PARTY HEREUNDER FOR ANY
          PUNITIVE, INCIDENTAL, INDIRECT, SPECIAL, RELIANCE OR CONSEQUENTIAL
          DAMAGES, INCLUDING LOST BUSINESS, REVENUE, OR PROFITS, WHETHER BASED
          ON BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, AND
          WHETHER OR NOT THE PARTY WAS ADVISED OF THE POSSIBILITY OF SUCH LOSS
          OR DAMAGES. EXCEPT WITH RESPECT TO SECTION 7, EITHER PARTY’S BREACH OF
          SECTION 9 OR CUSTOMERS BREACH OF ITS PAYMENT OBLIGATIONS HEREUNDER, IN
          NO EVENT WILL EITHER PARTY’S LIABILITY AND DAMAGES UNDER THIS
          AGREEMENT EXCEED THE SUM OF THE TOTAL FEES PAID TO UNDEFINED LABS
          UNDER THIS AGREEMENT DURING THE TWELVE MONTHS IMMEDIATELY PRECEDING
          THE DATE OF THE CLAIM. THE PARTIES AGREe THAT THE LIMITATIONS AND
          DISCLAIMERS OF LIABILITY SET FORTH IN THIS SECTION 10 WILL APPLY EVEN
          IF ANY LIMITED REMEDY SPECIFIED IN THIS AGREEMENT IS FOUND TO HAVE
          FAILED OF ITS ESSENTIAL PURPOSE AND REGARDLESS OF THE THEORY OF
          LIABILITY. The provisions of this Section 10 allocate risks under this
          Agreement between Customer and Undefined Labs, and Customer
          acknowledges that the fees payable hereunder reflect this allocation
          of risks and limitation of liability.
        </p>

        <h2>11. Miscellaneous</h2>
        <ol>
          <li>
            <strong>Relationship of the Parties</strong>. The parties are
            independent contractors with respect to each other. This Agreement
            does not constitute and shall not be construed as constituting a
            partnership or joint venture among the parties hereto, or an
            employee-employer relationship. No party shall have any right to
            obligate or bind any other party in any manner whatsoever.
          </li>
          <li>
            <strong>Publicity</strong>. Customer acknowledges that Undefined
            Labs may desire to use its name in websites, marketing collateral,
            press releases, product brochures and financial reports indicating
            that Customer is a customer of Undefined Labs, and Customer agrees
            that Undefined Labs may reasonably use its name in such a manner.
          </li>
          <li>
            <strong>Third Party Beneficiaries</strong>. Except as expressly set
            forth in this Agreement, nothing herein shall give, or is intended
            to give, any rights of any kind to any third parties.
          </li>
          <li>
            <strong>Assigment</strong>. Neither this Agreement nor any rights
            under this Agreement may be assigned or otherwise transferred or
            delegated by Customer, in whole or in part, whether voluntarily or
            by operation of law, including by way of or in connection with a
            sale of assets, merger or consolidation, without the prior written
            consent of Undefined Labs, which consent will not be unreasonably
            withheld. Subject to the foregoing, this Agreement will be binding
            upon and will inure to the benefit of the parties and their
            respective successors and assigns..
          </li>
          <li>
            <strong>Force Majeure</strong>. Except for payment obligations,
            neither party will be responsible for any failure or delay in its
            performance under this Agreement due to causes beyond its reasonable
            control, including, but not limited to, labor disputes, strikes,
            lockouts, internet or telecommunications failures, shortages of or
            inability to obtain labor, energy, or supplies, war, terrorism,
            riot, acts of God or governmental action, acts by hackers or other
            malicious third parties and problems with the Internet generally,
            and such performance shall be excused to the extent that it is
            prevented or delayed by reason of any of the foregoing.
          </li>
          <li>
            <strong>Notices</strong>. All notices under the terms of this
            Agreement shall be given in writing and sent by registered or
            certified mail, with postage prepaid and return receipt requested,
            to the addresses noted in the Order. All notices shall be presumed
            to have been given three business days following deposit in the mail
            as set forth in the foregoing.
          </li>
          <li>
            <strong>Amendments</strong>. An amendment of this Agreement shall be
            binding upon the parties so long as it is in writing and executed by
            both parties. No regular practice or method of dealing between the
            parties shall modify, interpret, supplement or alter in any manner
            the express terms of this Agreement.
          </li>
          <li>
            <strong>Waiver</strong>. Any waiver of the provisions of this
            Agreement or of a party’s rights or remedies under this Agreement
            must be in writing to be effective. Failure, neglect, or delay by a
            party to enforce the provisions of this Agreement or its rights or
            remedies at any time, will not be construed and will not be deemed
            to be a waiver of such party’s rights under this Agreement and will
            not in any way affect the validity of the whole or any part of this
            Agreement or prejudice such party’s right to take subsequent action.
            No exercise or enforcement by either party of any right or remedy
            under this Agreement will preclude the enforcement by such party of
            any other right or remedy under this Agreement or that such party is
            entitled by law to enforce.
          </li>
          <li>
            <strong>Severability; Counterparts</strong>. If any term, condition,
            or provision in this Agreement is found to be invalid, unlawful or
            unenforceable to any extent, the parties shall endeavor in good
            faith to agree to such amendments that will preserve, as far as
            possible, the intentions expressed in this Agreement. If the parties
            fail to agree on such an amendment, such invalid term, condition or
            provision will be severed from the remaining terms, conditions and
            provisions, which will continue to be valid and enforceable to the
            fullest extent permitted by law. This Agreement may be signed in
            counterparts. Each of them is an original, and all of them
            constitute one agreement.
          </li>
          <li>
            <strong>Purchase Orders</strong>. No terms, provisions or conditions
            of any purchase order, acknowledgement or other business form that
            Customer may issue in connection with this Agreement will have any
            effect on the rights, duties or obligations of the parties under, or
            otherwise modify, this Agreement, regardless of any failure of
            Undefined Labs to object to such terms, provisions or conditions.
          </li>
          <li>
            <strong>Governing Law; Jurisdiction</strong>. This Agreement will be
            interpreted and construed in accordance with the laws of the State
            of California and the United States of America, without regard to
            conflict of law principles, and excluding any application of the
            United Nations Convention on Contracts for the International Sale of
            Goods. The parties hereby consent to the exclusive jurisdiction of
            the state and federal courts located in California for resolution of
            any disputes arising out of this Agreement.
          </li>
          <li>
            <strong>Entire Agreement</strong>. This Agreement constitutes the
            complete, final and exclusive agreement between the parties with
            respect to the subject matter hereof, and supersedes any and all
            prior or contemporaneous oral or written representations,
            understandings, agreements or communications between them concerning
            the subject matter hereof. Neither party is relying upon any
            warranties, representations, assurances or inducements not expressly
            set forth herein.
          </li>
        </ol>
      </section>
    </Layout>
  )
}

export default TermsPage
